import styles from '../cssmodules/ourtarif.module.css'

const OurTarif = () => {
  return (
    <div className={styles.block}>
        <h1>Наші тарифи</h1>
        <ul className={styles.list}>
            <li>100 Мб/с - 200 грн.міс</li>
            <li>300 Мб/с - 260 грн.міс</li>
            <li>Статичний IP - 320 грн.міс</li>
        </ul>
    </div>
  )
}

export default OurTarif