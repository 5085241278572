import React, { useEffect, useState } from "react";
import Articles from "./Articles";
import axios from 'axios';

const Article = () => {
  const API_URL = 'https://onlinedata.online.sumy.ua/api/v1/articles/';

  const [articles, setArticles] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(API_URL);
        const data = response.data;

        // Проверяем, есть ли ключ objects в данных
        if (data.objects) {
          setArticles(data.objects);
        } else {
          setError("Data format is not as expected");
        }
      } catch (error) {
        setError(error.message);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {articles.length > 0 ? (
        articles.map((article) => (
          <Articles key={article.id} {...article} />
        ))
      ) : (
        <p>Заждіть хвилинку...</p>
      )}
      {error && <p>Error: {error}</p>}
    </>
  );
};

export default Article;
