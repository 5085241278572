// Tarif.js

import { useState, useEffect } from "react";
import axios from 'axios';
import Listtarif from "./Listtarif";
import styles from '../cssmodules/Listtarif.module.css';

const Tarif = () => {
  const API_URL = 'https://onlinedata.online.sumy.ua/api/v1/tarif/';

  const [tarf, setTar] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(API_URL);
        const data = response.data;

        if (data.objects) {
          setTar(data.objects);
        } else {
          setError("Data format is not as expected");
        }
      } catch (error) {
        setError(error.message);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {tarf && tarf.length > 0 ? (
         
        <>
        <h2 className="title_tarif">Тарифи Суми Онлайн</h2>
        <table className={styles}> 
          <thead>
            <tr>
              <th>Назва тарифу</th>
              <th>Швидкість тарифу</th>
              <th>Вартість(місяць)</th>
            </tr>
          </thead>
          <tbody>
            {tarf.map((t) => (
              <Listtarif key={t.id} {...t} />
            ))}
          </tbody>
        </table>

        <p className="info">
        Інтернет провайдер "Суми Онлайн" здійснює послуги по підключенню до глобальної мережі інтернет за технологією оптичного доступу за системою GEPON. Ця технологія дає можливість надання послуги згідно сучасним вимогам інформаційним технологій. По місту Суми ми охоплюємо приватний сектор в районах: Баси,Тополянська та Баранівка (можуть розглядатися і прилеглі до них райони). Вартість підключення - є договірною і в першу чергу залежить від умов розміщення об`єктів-споживачів. Підключення відбувається на основі заключення двухстороннього договору та внесення оплати за послугу підключення. Клієнтам буде надана можливість використовувати послугу інтернет 24 години на добу та 7 днів на тиждень згідно умов договору(про зміни чи відсутність даної послуги будуть сповіщатись на офіційному сайті в розділі "Новини" (інформація буде розміщуватись в цьому розділі не менш цим за дві доби до зміни і не буде доводитися шляхом телефонного сповіщення) Оплату за послуги можливо проводити через платіжні термінали EasyPay та сервіси обслуговування Приват Банку.
        </p>


        </>
      ) : (
        <p>Заждіть хвилинку..</p>
      )}
      {error && <p>Ошибка: {error}</p>}
    </>
  );
};

export default Tarif;
