import TextDogovor from "./TextDogovor"


const Agreement = () => {
  return (
    <div>
      <TextDogovor />
    </div>

  )
}

export default Agreement