import styles from '../videoinstr/video.module.css'

const IstrUser = (props) => {

  const url=props.url
 
  return (
    <div className={styles.block}>
      <h4>{props.title}</h4>
      <iframe className={styles.frame} src={`https://www.youtube.com/embed/${url}`}
      title="YouTube video player" frameborder="0" 
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
      allowfullscreen></iframe>
    </div>
  )
}

export default IstrUser