import styles from '../cssmodules/article.module.css'


const Articles = (props) => {
    const {title,text,image}=props
  return (
    <div className={styles.container}>
            <h1 className={styles.title}>{title}</h1>
            <p className={styles.text}>
                {text}
                <br />
            <p className={styles.blocpicture}>
                <img className={styles.picture} src={`https://onlinedata.online.sumy.ua/${image}`} alt="" />
            </p>
            </p>
        </div>
  )
}

export default Articles