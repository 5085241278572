import axios from 'axios'
import InstrUser from './IstrUser'
import { useEffect, useState } from 'react'
import styles from '../videoinstr/video.module.css'

const ListsInstr = () => {

  const API_URL = 'https://onlinedata.online.sumy.ua/api/v1/video/';
       
  const [video,setVideo]=useState([])
  const [error,setError]=useState('')

useEffect(()=>{
    const fetchData=async()=>{
      try {
          const response=await axios.get(API_URL)
          const data=response.data
          if (data.objects) {
            setVideo(data.objects);
            
          } else {
            setError("Data format is not as expected");
          }
        } catch (error) {
          setError(error.message);
        }
      };
  
      fetchData();
    }, []);

  console.log(error)

    return (
      <div className={styles.template}>
        {video.map((item, index) => (
          <InstrUser key={index} {...item}  /> 
        ))}
      </div>
    );
  };

export default ListsInstr