

const WeatherCard = () => {
  const whether = '<img src="https://w.bookcdn.com/weather/picture/3_15734_1_29_137AE9_160_ffffff_333333_08488D_1_ffffff_333333_0_6.png?scode=58102&domid=966&anc_id=62951"  alt="booked.net"/>';

  return (
    <div className="card" >
     <div dangerouslySetInnerHTML={{ __html: whether }}></div>
    </div>
  );
};

export default WeatherCard;
