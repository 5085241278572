import {  useState } from 'react';
import axios from 'axios'
import styles from '../usercach/usercash.module.css';
import { BsCashCoin } from 'react-icons/bs';

const ModalUser = () => {

    const API_URL = 'https://billing.online.sumy.ua/control/phpfor/site.php';

  const [ident, setIdent] = useState('');
  const [klient,setKlient]=useState([])
  const [adres,setAdres] = useState([])
  const [dom,setDom] = useState([])
  const [balance, setBalance] = useState('');
  const [error,setError] = useState('')
  const [abonentNotFound, setAbonentNotFound] = useState(false);
  

  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_URL}?id=${ident}`);
      const data = response.data;
     
      if (data.length>0 && data[0].realname && data[0].streetname) {

      setIdent('')  
      setKlient(data[0].realname);
      setAdres(data[0].streetname);
      setDom(data[0].buildnum)
      setBalance(data[0].Cash);
      setAbonentNotFound(false)

      } else {
        setAbonentNotFound(true);
        setError("");
        setIdent('') 
      }
    } catch (error) {
      setAbonentNotFound(true);
      setError(error.message);
      setIdent('') 
    }
  };

  const handleButtonClick = () => {
    // Проверка наличия идентификатора перед выполнением запроса
    if (ident) {
      fetchData();
    } else {
      setError("Введіть правильне значення ідентифікатора");
    }
  };

  return (
    <div className={styles.block}>
      <h3 className={styles.title}>Cтан рахунку</h3>
      <input
        className={styles.inputuser}
        type="search"
        placeholder="Платіжний ідентифікатор"
        value={ident}
        onChange={(e) => {
          setIdent(e.target.value);
        }}
      />
      <button className={styles.getinfo} onClick={handleButtonClick}>
        ОТРИМАТИ ІНФОРМАЦІЮ <BsCashCoin />
      </button>

      {abonentNotFound ? (
     <p className={styles.not}>Такого абонента не існує</p>
        ) : (

      <div className={styles.user}>
        {klient.length===0 ? 
        (<ul style={{display:'none'}}>
          <li>{klient}</li>
          <li>{adres}, {dom}</li>
          <li><b>Залишок: </b>{balance} грн</li>
        </ul>):(<ul style={{display:'block'}}>
          <li>{klient}</li>
          <li>{adres}, {dom}</li>
          <li><b>Залишок: </b>{balance} грн</li>
        </ul>)

}

      </div>
        )}

      {error && <p className={styles.error}>{error}</p>}
    </div>
  );
};
export default ModalUser;
