import styles from '../cssmodules/new.module.css'


const News = (props) => {
  const {title,text}=props
  return (
    <div className={styles}>
       <h1 className={styles.title}>{title}</h1> 
       <p className={styles.text}>{text}</p>        
        
    </div>
  )
}

export default News