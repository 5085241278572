import Article from "./Article"



const Home = () => {
  return (
       <>
       <Article />
       </>
  )
}

export default Home  