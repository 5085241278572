import Baner from '../images/bn.jpg'

const Header = () => {
  return  <div style={{ margin: '10px' }}>
  <img src={Baner} alt="harry potter" />
</div>   

    
  
}

export default Header