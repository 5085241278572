import React from 'react'

const Services = () => {
  return (
    <div className='service'>
        <h3>Перелік клієнтських послуг ТОВ "Суми Онлайн"</h3>
        <table>
    <thead>
        <tr>
            <th>№</th>
            <th>Назва послуги</th>
            <th>Вартість</th>
           
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>1</td>
            <td>Виклик майстра додому</td>
            <td>350 грн</td>
           
        </tr>
        <tr>
            <td>2</td>
            <td>Налаштування роутера</td>
            <td>100 грн</td>
            
        </tr>
        <tr>
            <td>3</td>
            <td>Налаштування інтернет додатків на телевізорах та смарт приставках</td>
            <td>300 грн</td>
            
        </tr>
        <tr>
            <td>4</td>
            <td>Ремонт оптичної мережі від місця з`єднання до абонента</td>
            <td>від 400 грн</td>
            
        </tr>





       
    </tbody>
</table>
    </div>
  )
}

export default Services