import { TfiHome } from "react-icons/tfi";
import { FaPhoneVolume } from "react-icons/fa6";
import { FaTelegram } from "react-icons/fa";
import { SiViber } from "react-icons/si";
import { MdOutlineAlternateEmail } from "react-icons/md";
import styles from '../cssmodules/about.module.css'

const About = () => {
  return (
    <>
  <h2>Наші контакти:</h2>
  
   <ul className={styles.list}>
    <li><TfiHome className={styles.adr} /> <b>м.Суми вул.Новомістенської сотні,1(Рибалко) </b> </li>
    <li><FaPhoneVolume /> <b>050 634 80 93</b>  </li>
    <li><FaTelegram className={styles.telegram}/> <b>050 634 80 93</b></li>
    <li><SiViber className={styles.viber} /> <b>050 634 80 93</b></li>
    <li><MdOutlineAlternateEmail /> <b>info@online.sumy.ua</b></li>
   </ul>

</>

  )
}

export default About