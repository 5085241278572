import Pay from '../images/pays.jpg'

const PrivatPays = () => {

const Privat = ()=>{window.location.href='https://next.privat24.ua/payments/form/%7B%22token%22:%22691a9dd7-25e4-4bd8-aeb9-35c1d56d5457%22%7D'}
  


  return  <div >
  <img title='Для оплати натисність' className='pays' c src={Pay} alt="Privat" onClick={Privat} />
</div>  
 
}

export default PrivatPays