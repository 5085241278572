


import styles from '../cssmodules/Listtarif.module.css';

const Listtarif = ({  name_tarif, speed_tarif, cost_tarif }) => {
  return (
    <tr className={styles}>
   
      <td>{name_tarif}</td>
      <td>{speed_tarif}</td>
      <td>{cost_tarif}</td>
    </tr>
  );
};

export default Listtarif;


